<template>
  <div>
    <b-card>
      <!-- form -->
      <b-overlay
        :show="loadingOverlay"
        rounded="sm"
      >

        <b-alert
          v-if="errorMessage"
          variant="warning"
          show
        >
          <h4 class="alert-heading">
            {{ errorMessage }}
          </h4>
        </b-alert>

        <b-row
          v-if="apiInfoData"
          class="mb-2"
        >
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <feather-icon
                icon="ActivityIcon"
                size="18"
              />
              <h4 class="mb-0 ml-75">
                {{ $t('contaConfiguracao.binanceApi.apiStatus') }}
              </h4>
            </div>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col
                lg="3"
                md="4"
                class="mb-2"
              >
                {{ $t('contaConfiguracao.binanceApi.criacao') }}: {{ formatDateBR(new Date(apiInfoData.createTime)) }}
              </b-col>
              <b-col
                lg="3"
                md="4"
                class="mb-2"
              >
                {{ $t('contaConfiguracao.binanceApi.expiracao.default') }}: {{ apiInfoData.tradingAuthorityExpirationTime ? formatDateBR(new Date(apiInfoData.tradingAuthorityExpirationTime)) : $t('contaConfiguracao.binanceApi.expiracao.naoExpira') }}
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col
                v-if="showSpot"
                lg="3"
                md="4"
                class="mb-2"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${apiInfoData.enableSpotAndMarginTrading ? 'success' : 'danger'}`"
                />
                <span>  {{ $t('contaConfiguracao.binanceApi.spot') }} {{ apiInfoData.enableSpotAndMarginTrading ? $t('contaConfiguracao.binanceApi.habilitado') : $t('contaConfiguracao.binanceApi.desabilitado') }}</span>
              </b-col>
              <b-col
                v-if="showFutures"
                lg="3"
                md="4"
                class="mb-2"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${apiInfoData.enableFutures ? 'success' : 'danger'}`"
                />
                <span>{{ $t('contaConfiguracao.binanceApi.futures') }} {{ apiInfoData.enableFutures ? $t('contaConfiguracao.binanceApi.habilitado') : $t('contaConfiguracao.binanceApi.desabilitado') }}</span>
              </b-col>
              <b-col
                v-if="showFutures"
                lg="3"
                md="4"
                class="mb-2"
              >
                <span>{{ $t('contaConfiguracao.binanceApi.modoPosicao') }}
                  <b-badge
                    variant="warning"
                  >
                    {{ futuresModoPosicao ? 'Hedge' : 'One Way' }}
                  </b-badge>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="link"
                    size="sm"
                    @click="confirmarAlterarFuturesModoPosicao"
                  >
                    {{ $t('contaConfiguracao.binanceApi.alterarBtn') }}
                  </b-button>
                </span>
              </b-col>

            </b-row>
          </b-col>
        </b-row>

        <validation-observer ref="simpleRules">
          <b-form
            autocomplete="off"
            @submit.prevent="validationForm"
          >
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-2">
                  <feather-icon
                    icon="EditIcon"
                    size="18"
                  />
                  <h4 class="mb-0 ml-75">
                    {{ $t('contaConfiguracao.binanceApi.cadastro') }}
                  </h4>
                </div>
              </b-col>
              <!-- Api password -->
              <b-col md="6">
                <b-form-group
                  label-for="account-Api-password"
                  :label="$t('contaConfiguracao.binanceApi.labelApiKey')"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="API Key"
                    vid="Password"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          v-model="data.binanceApiKey"
                          :type="passwordFieldTypeApi"
                          :state="errors.length > 0 ? false:null"
                          placeholder="API Key"
                          autocomplete="off"
                          :disabled="data.boqueiaAlterarApi==true"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIconApi"
                            class="cursor-pointer"
                            @click="togglePasswordApi"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--/ Api password -->

              <!-- Secret password -->
              <b-col md="6">
                <b-form-group
                  label-for="account-Secret-Api-password"
                  :label="$t('contaConfiguracao.binanceApi.labelApiSecret')"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="API Secret"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          v-model="data.binanceSecretKey"
                          :type="passwordFieldTypeSecret"
                          :state="errors.length > 0 ? false:null"
                          placeholder="API Secret"
                          autocomplete="off"
                          :disabled="data.boqueiaAlterarApi==true"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIconSecret"
                            class="cursor-pointer"
                            @click="togglePasswordSecret"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--/ Secret password -->

              <!-- buttons -->
              <b-col cols="12">
                <b-button
                  v-if="data.boqueiaAlterarApi!=true"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-1 mr-1"
                  type="submit"
                >
                  {{ $t('contaConfiguracao.salvarBtn') }}
                </b-button>
              </b-col>
              <!--/ buttons -->
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BOverlay, BAlert, BBadge,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import { isHaveProduct } from '@/auth/utils'
import { formatDateBR } from '@/utils/filter'
import configuracaoContaStoreModule from './configuracaoContaStoreModule'

const CONFIGURACAO_CONTA_STORE_MODULE_NAME = 'configuracao-conta'

export default {

  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    BAlert,
    BBadge,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      loadingOverlay: false,
      passwordFieldTypeApi: 'password',
      passwordFieldTypeSecret: 'password',
      required,
      data: {
        binanceApiKey: null,
        binanceSecretKey: null,
        boqueiaAlterarApi: null,
      },
      futuresModoPosicao: null,
      apiInfoData: null,
      errorMessage: null,
      formatDateBR,
    }
  },

  computed: {
    passwordToggleIconApi() {
      return this.passwordFieldTypeApi === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconSecret() {
      return this.passwordFieldTypeSecret === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

    showFutures() {
      return isHaveProduct('futures')
    },

    showSpot() {
      return isHaveProduct('spot')
    },
  },

  created() {
    if (!store.hasModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)) store.registerModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME, configuracaoContaStoreModule)
    this.getConfigBinanceApi()
  },

  beforeDestroy() {
    if (store.hasModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)) store.unregisterModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)
  },

  methods: {

    confirmarAlterarFuturesModoPosicao() {
      /* eslint no-param-reassign: "error" */

      this.$swal({
        title: 'Alteração do Modo de posição',
        text: `Confirma alteração para o modo ${this.futuresModoPosicao ? 'One Way' : 'Hedge'}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmo',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.alterarModoPosicaoHedge()
        }
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loadingOverlay = true
          this.$store.dispatch('configuracao-conta/setConfigBinanceApi', this.data).then(() => {
            this.getInfoBinanceApi()
            this.$swal({
              title: 'Sucesso!',
              text: 'API configurada com sucesso.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }).catch(error => {
            this.$swal({
              title: 'Error!',
              text: error.response.data[0].erro,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }).finally(() => {
            this.loadingOverlay = false
          })
        }
      })
    },

    togglePasswordApi() {
      this.passwordFieldTypeApi = this.passwordFieldTypeApi === 'password' ? 'text' : 'password'
    },

    togglePasswordSecret() {
      this.passwordFieldTypeSecret = this.passwordFieldTypeSecret === 'password' ? 'text' : 'password'
    },

    getConfigBinanceApi() {
      this.$store.dispatch('configuracao-conta/getConfigBinanceApi')
        .then(res => {
          this.data = res.data
          this.getInfoBinanceApi()
        })
    },

    getInfoBinanceApi() {
      if (this.data.binanceApiKey && this.data.binanceSecretKey) {
        this.$store.dispatch('configuracao-conta/getInfoBinanceApi')
          .then(res => {
            this.apiInfoData = res.data
            this.errorMessage = null

            if (this.showFutures) {
              this.getModoPosicaoHedge()
            }
          }).catch(error => {
            this.errorMessage = error.data
          })
      }
    },

    getModoPosicaoHedge() {
      this.$store.dispatch('configuracao-conta/getModoPosicaoHedge')
        .then(res => {
          this.futuresModoPosicao = res.data
        })
    },

    alterarModoPosicaoHedge() {
      const modeParam = !this.futuresModoPosicao
      this.$store.dispatch('configuracao-conta/setModoPosicaoHedge', modeParam.toString())
        .then(() => {
          this.getModoPosicaoHedge()
        }).catch(error => {
          this.errorMessage = error.data.message
        })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
