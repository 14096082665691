<template>
  <div>
    <b-card>
      <!-- form -->
      <b-overlay
        :show="loadingOverlay"
        rounded="sm"
      >
        <validation-observer ref="simpleRules">
          <b-form @submit.prevent="validationForm">
            <b-row>
              <!-- old password -->
              <b-col md="6">
                <b-form-group
                  :label="$t('contaConfiguracao.password.campoSenhaAtual')"
                  label-for="account-old-password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Senha atual"
                    rules="required|min:6"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="account-old-password"
                          v-model="passwordValueOld"
                          name="old-password"
                          :state="errors.length > 0 ? false:null"
                          :type="passwordFieldTypeOld"
                          :placeholder="$t('contaConfiguracao.password.campoSenhaAtual')"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIconOld"
                            class="cursor-pointer"
                            @click="togglePasswordOld"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--/ old password -->
            </b-row>
            <b-row>
              <!-- new password -->
              <b-col md="6">
                <b-form-group
                  label-for="account-new-password"
                  :label="$t('contaConfiguracao.password.campoNovaSenha')"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nova senha"
                    vid="Password"
                    rules="required|min:6"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="account-new-password"
                          v-model="newPasswordValue"
                          :type="passwordFieldTypeNew"
                          :state="errors.length > 0 ? false:null"
                          name="new-password"
                          :placeholder="$t('contaConfiguracao.password.campoNovaSenha')"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIconNew"
                            class="cursor-pointer"
                            @click="togglePasswordNew"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--/ new password -->

              <!-- retype password -->
              <b-col md="6">
                <b-form-group
                  label-for="account-retype-new-password"
                  :label="$t('contaConfiguracao.password.campoConfirmarSenha')"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Confirmar nova senha"
                    rules="required|confirmed:Password"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="account-retype-new-password"
                          v-model="RetypePassword"
                          :type="passwordFieldTypeRetype"
                          :state="errors.length > 0 ? false:null"
                          name="retype-password"
                          :placeholder="$t('contaConfiguracao.password.campoConfirmarSenha')"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIconRetype"
                            class="cursor-pointer"
                            @click="togglePasswordRetype"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--/ retype password -->

              <!-- buttons -->
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-1 mr-1"
                  type="submit"
                >
                  {{ $t('contaConfiguracao.salvarBtn') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  class="mt-1"
                >
                  {{ $t('contaConfiguracao.password.limparBtn') }}
                </b-button>
              </b-col>
              <!--/ buttons -->
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BOverlay,
} from 'bootstrap-vue'
import {
  required, min, confirmed,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import configuracaoContaStoreModule from './configuracaoContaStoreModule'

const CONFIGURACAO_CONTA_STORE_MODULE_NAME = 'configuracao-conta'

export default {

  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      loadingOverlay: false,
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required,
      min,
      confirmed,
    }
  },

  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  created() {
    if (!store.hasModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)) store.registerModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME, configuracaoContaStoreModule)
  },

  beforeDestroy() {
    if (store.hasModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)) store.unregisterModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)
  },

  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loadingOverlay = true
          this.$store.dispatch('configuracao-conta/alterarSenha', { senhaAtual: this.passwordValueOld, novaSenha: this.newPasswordValue, confirm_new_password: this.RetypePassword }).then(() => {
            this.$swal({
              title: 'Sucesso!',
              text: 'Sua senha foi alterada',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.userEmail = null
            this.passwordValueOld = null
            this.newPasswordValue = null
            this.RetypePassword = null
            this.$refs.simpleRules.reset()
          }).catch(error => {
            this.$swal({
              title: 'Error!',
              text: error.data[0].erro,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }).finally(() => {
            this.loadingOverlay = false
          })
        }
      })
    },

    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },

    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },

    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
