<template>
  <div>
    <b-card>
      <b-overlay
        :show="loadingOverlay"
        rounded="sm"
      >
        <validation-observer ref="simpleRules">
          <b-form
            @submit.prevent="validationForm"
          >
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-2">
                  <feather-icon
                    icon="SendIcon"
                    size="18"
                  />
                  <h4 class="mb-0 ml-75">
                    {{ $t('contaConfiguracao.notificacoes.title') }}
                  </h4>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mb-2"
              >
                <b-form-checkbox
                  id="accountSwitch1"
                  v-model="data.telegramRecebe"
                  name="check-button"
                  switch
                  inline
                >
                  <span>{{ $t('contaConfiguracao.notificacoes.labelCheckBox') }}</span>
                </b-form-checkbox>
              </b-col>
              <template v-if="data.telegramRecebe">
                <b-col sm="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Bot Token"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('contaConfiguracao.notificacoes.labelBotToken') "
                      label-for="Bot Token"
                    >
                      <b-form-input
                        v-model="data.telegramBotToken"
                        placeholder="Bot Token"
                        name="Bot Token"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col sm="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Chat ID"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('contaConfiguracao.notificacoes.labelChatId')"
                      label-for="Chat ID"
                      :description="$t('contaConfiguracao.notificacoes.descriptionChatID') "
                    >

                      <b-overlay
                        :show="loadingOverlayBuscar"
                        rounded="lg"
                        opacity="0.6"
                        @hidden="onHidden"
                      >
                        <template v-slot:overlay>
                          <div class="d-flex align-items-center">
                            <b-spinner
                              small
                              type="grow"
                              variant="secondary"
                            />
                            <b-spinner
                              type="grow"
                              variant="dark"
                            />
                            <b-spinner
                              small
                              type="grow"
                              variant="secondary"
                            />
                          </div>
                        </template>
                        <b-input-group>
                          <b-form-input
                            v-model="data.telegramChatId"
                            name="Chat ID"
                            placeholder="Chat ID"
                            :disabled="loadingOverlayBuscar"
                            :aria-hidden="loadingOverlayBuscar ? 'true' : null"
                          />
                          <b-input-group-append>
                            <b-button
                              v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                              variant="outline-info"
                              @click.prevent="telegramBuscarIdChat"
                            >
                            {{ $t('contaConfiguracao.notificacoes.buscarIdBtn') }}
                            </b-button>
                          </b-input-group-append>

                        </b-input-group>
                      </b-overlay>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </template>

              <b-col cols="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1 mt-1"
                  type="submit"
                >
                {{ $t('contaConfiguracao.salvarBtn') }}
                </b-button>
                <b-button
                  v-if="habilitaTeste && data.telegramRecebe"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mr-2 mt-1"
                  @click.prevent="enviarTeste"
                >
                  {{ $t('contaConfiguracao.notificacoes.enviarTesteBtn') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BRow, BCol, BCard, BFormCheckbox, BForm, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BOverlay, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import configuracaoContaStoreModule from './configuracaoContaStoreModule'

const CONFIGURACAO_CONTA_STORE_MODULE_NAME = 'configuracao-conta'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BOverlay,
    BSpinner,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      loadingOverlayBuscar: false,
      loadingOverlay: false,
      habilitaTeste: false,
      data: {
        telegramRecebe: '',
        telegramBotToken: '',
        telegramChatId: '',
      },
    }
  },

  created() {
    if (!store.hasModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)) store.registerModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME, configuracaoContaStoreModule)
    this.getConfigNotificacao()
  },

  beforeDestroy() {
    if (store.hasModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)) store.unregisterModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)
  },

  methods: {

    enviarTeste() {
      this.loadingOverlay = true
      this.$store.dispatch('configuracao-conta/telegramNotificacaoEnvioTeste')
        .then(() => {
          this.$swal({
            title: 'Notificação enviada com sucesso',
            text: this.data.telegramRecebe ? 'Enviamos uma mensagem de teste para o seu Telegram. Verifique se chegou.' : '',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }).catch(error => {
          this.$swal({
            title: 'Error!',
            text: error.response.data[0].erro,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }).finally(() => {
          this.loadingOverlay = false
        })
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loadingOverlay = true
          this.$store.dispatch('configuracao-conta/setConfigNotificacao', this.data)
            .then(() => {
              if (this.data.telegramRecebe) {
                this.$store.dispatch('configuracao-conta/telegramNotificacaoEnvioTeste')
                  .then(() => {
                  })
              }
              this.getConfigNotificacao()
              this.$swal({
                title: 'Notificações alteradas com sucesso',
                text: this.data.telegramRecebe ? 'Enviamos uma mensagem de teste para o seu Telegram. Verifique se chegou.' : '',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              this.$swal({
                title: 'Error!',
                text: error.response.data[0].erro,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).finally(() => {
              this.loadingOverlay = false
            })
        }
      })
    },

    telegramBuscarIdChat() {
      return new Promise(() => {
        this.loadingOverlayBuscar = true
        axios.get(`https://api.telegram.org/bot${this.data.telegramBotToken}/getUpdates`)
          .then(response => {
            const grupoId = response.data.result[response.data.result.length - 1].message.chat.id
            if (grupoId !== '') {
              this.data.telegramChatId = response.data.result[response.data.result.length - 1].message.chat.id
              this.$swal({
                title: 'Sucesso!',
                text: 'Chat ID Recuperado com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              this.$swal({
                title: 'Ocorreu um erro ao recuperar o Chat ID',
                text: 'Verifique se enviou uma mensagem privada para o bot',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          .catch(err => {
            this.$swal({
              title: 'Ocorreu um erro no Telegram',
              text: err.response.data.description,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }).finally(() => {
            this.loadingOverlayBuscar = false
          })
      })
    },

    getConfigNotificacao() {
      /* eslint-disable */
      let _self = this
      this.$store.dispatch('configuracao-conta/getConfigNotificacao')
        .then(res => {
          this.data = res.data
          _self.habilitaTeste = res.data.telegramBotToken != null && res.data.telegramChatId != null && res.data.telegramRecebe // Para saber se foi salvo
        })
    },

    onHidden() {
      this.$refs.button.focus()
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
